.App {
  text-align: center;
  max-width: 610px;
  margin: auto;
  padding: 20px;
  position: relative; /* 배경 이미지를 위한 위치 지정 */
  padding-bottom: 60px; /* 푸터 높이보다 조금 더 큰 값으로 설정 */
  padding-top: 10px;
}

.draw-notice {
  font-size: 12px; /* 작은 글씨 크기 */
  text-align: center; /* 중앙 정렬 */
  margin-top: 2px; /* 상단 여백 */
  margin-bottom: 20px; /* 하단 여백, 타이틀과의 간격 조정 */
}

.App:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./hanew.png');
  background-size: cover;
  opacity: 0.4; /* 투명도 설정 */
  z-index: -1; /* 내용물이 배경 위에 오도록 설정 */
}

.input-group {
  width: 60%; /* .input-group의 가로 사이즈 조정 */
  margin: 0 auto; /* 상하 마진 0, 좌우 마진 자동으로 설정하여 중앙 정렬 */
  margin-bottom: 20px; /* 하단 마진은 요소 간 간격을 위해 유지 */
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  width: 70%; /* 가로 길이를 현재의 반으로 조정 */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* 텍스트 필드를 중앙에 위치시키기 위해 추가 */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  position: relative; /* 가상 요소 배경 위에 표시 */
  z-index: 1;
}

button:hover {
  background-color: #0056b3;
}

.result {
  margin-top: 20px;
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative; /* 가상 요소 배경 위에 표시 */
  z-index: 1;


}
.error-message {
  margin-top: 10px;
  color: red;
}


.title {
  margin-bottom: 1px; 
}
.count {
  margin-top : -25px;
  margin-bottom: 25px; 
  font-size: 14px;
  color: black;
}


.spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #007bff;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin{
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.tabs button {
  width: 100px; /* 버튼 너비 고정 */
  margin: 0 10px; /* 버튼 간 간격 */
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.tabs button:hover {
  background-color: #0056b3;
}

.tabs button.active-tab {
  font-weight: bold; /* 활성 탭의 텍스트를 굵게 */
}

.tab-content {
  min-height: 200px; /* 탭 컨텐츠의 최소 높이 설정 */
  overflow: hidden; /* 오버플로우 숨김 처리 */
}

.tab-log {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.tab-log table {
  width: 80%; /* 또는 원하는 너비 */
  border-collapse: collapse;
  margin-bottom: 20px; /* 테이블 하단에 여백 추가 */

}

.tab-log th, .tab-log td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left; /* 텍스트 정렬 */
}

.tab-log tr:nth-child(even) {
  background-color:lightgray;
}

.tab-log th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: slategray;
  color: white;
}

.load-more-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  width: 150px; /* 버튼 크기 고정 */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.load-more-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

